import { Grid } from '@material-ui/core';
import { Formik, FormikProps, useFormikContext } from 'formik';
import { cloneDeep, filter, get, includes, map, size } from 'lodash';
import { Observer } from 'mobx-react';
import React, { useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { DocumentRevisionFormHeader, DocumentRevisionFormProgressBar, SM, SMBox, SMFormState, SMTabs, SMTabsState, SMTemplate } from '../../../../../../../App';
import FBTrainingInfoContainer from '../../../../../../../App/Enlil/Features/DocumentRevisionForm/Components/FBTrainingInfo/FBTrainingInfo.container';
import { withSMForm } from '../../../../../../../App/Shifamed/Components/SMForm/SMForm.hoc';
import { withSMTabs } from '../../../../../../../App/Shifamed/Components/SMTabs/SMTabs.hoc';
import { emptyFunction } from '../../../../../../../common/utils/helpers';
import { authSelectors } from '../../../../../../../state/ducks/auth';
import { documentTypeSelectors } from '../../../../../../../state/ducks/documentRevisions/documentType';
import { DocumentGroupType, DOC_TYPE_GROUP, TabInfo, TabTypeOptions, WorkOrderTypes } from '../../../../../../../state/ducks/documentRevisions/documentType/types';
import { DocumentRevision, FBOutputDocumentType, SmartReferenceType } from '../../../../../../../state/ducks/documentRevisions/types';
import { ApplicationState } from '../../../../../../../state/reducers';
import FBMaterialDisposition from '../../../../../../change.request/FBMaterialDisposition';
import { DocTypeGroups } from '../../../../../../dashboard.new/line.items/common/types';
import AttachmentsField from '../../../../../../document.revision/form/attachments';
import { FB, FBBOM, FBEditorState, FBProvider, FBSchema, FBSchemaProps, FBWorkspaceMode, FBWorkspaceModeOptions, FBWorkspaceState } from '../../../../../../form.builder';
import FBAllocationTreeList from '../../../../../../form.builder/FBAllocation/components/treelist';
import FBLHRSummary from '../../../../../../form.builder/FBLHRSummary';
import FBMaterialEqInfoContainer from '../../../../../../form.builder/FBMaterialEquipmentInfo/FBMaterailEqInfo.container';
import FBDataStore from '../../../../../../form.builder/FBStore/FBDataStore';
import { DocumentCloneTypeOptions } from '../../../../../Clone.container';
import { isOperator } from '../../../../../DocumentRevisionDisplay.container';
import { checkIsDocumentLHR, checkIsDocumentMPIOutput, checkIsDocumentOTHER, checkIsDocumentPart, checkIsDocumentWO } from '../../../../../helpers/checkDocumentGroup';
import DocumentRevisionFormPresenter from '../../../../DocumentRevisionForm.presenter';
import { DocumentRevisionFormValues } from '../../../../types';
import LifecyclePhaseSelect from '../../../LifecyclePhaseSelect';
import RelatedEquipments, { shouldShowRelatedEquipmentsTable } from '../../../related.equipments';
import RelatedPartsWrapper from '../../../related.parts/RelatedParts.wrap';
import styles from './styles';

interface ContainerProps {
  docRevId?: string
  formPreview?: boolean
  _tabsState?: SMTabsState
  _formState?: SMFormState
}

interface ComponentProps extends ContainerProps {
  formik: FormikProps<DocumentRevisionFormValues>
}

const DocumentPreview: React.FunctionComponent<ComponentProps> = ({
  docRevId,
  _tabsState,
  _formState,
  formik,
  formPreview = false,
}) => {
  const classes = styles();
  const { _documentRevisionFormState } = SM.useStores();
  const { workspaceState } = FB.useStores();
  const previewWorkspaceState = FB.useRef<FBWorkspaceState>(FBWorkspaceState, {
    schema: workspaceState?.schema ?? _documentRevisionFormState?.schema,
    mode: FBWorkspaceModeOptions.FORM,
    autosave: false,
  });

  const documentTypesById = useSelector(documentTypeSelectors.byId);

  // @todo: Mix between DocumentRevisionSummary and DocumentRevisionForm.
  // @todo: Move these elements to one component to reuse.
  // Create documentRevision and fill it
  const documentRevision = formik.values as unknown as DocumentRevision;
  const initialValues = formik.values as unknown as DocumentRevisionFormValues;

  const docTypeId = documentRevision?.document?.documentType?.id as string;
  const documentType = documentTypesById[docTypeId];
  const docTypeGroup = get(documentType, 'group', DOC_TYPE_GROUP.OTHER);
  const templateOutputDocumentTypes = documentRevision?.formTemplate?.outputDocumentTypes?.[0] as FBOutputDocumentType;
  let schema = workspaceState?.schema ?? _documentRevisionFormState?.schema;

  const outputTypeId = (
    documentRevision?.outputDocumentTypes?.value
    ?? documentRevision?.outputDocumentTypes?.[0]?.value
    ?? templateOutputDocumentTypes?.id
  ) as string;
  const outputType = documentTypesById[outputTypeId];

  // By default the documentType points to being a Form, but while previewing, it needs to
  // behave as if it is the documentType that was selected as an output.
  if (documentRevision && outputType) {
    FBDataStore.documentTypes = documentRevision.document.documentType;
    documentRevision.document.documentType = outputType;
  }
  const outputTypeGroup = get(outputType, 'group');

  const group: DocumentGroupType = docTypeGroup === DocTypeGroups.FORM ? outputTypeGroup : docTypeGroup;
  const isWO = group === DOC_TYPE_GROUP.WORK_ORDER || checkIsDocumentWO(documentType?.groupOptions);
  const email = useSelector(authSelectors.currentUserEmail);
  const isOwner = documentRevision?.owner ? documentRevision?.owner.user.email === email : true;
  const isLHR = checkIsDocumentLHR(documentType.groupOptions);
  const isTypeMpiDoc = checkIsDocumentMPIOutput(documentType.groupOptions);
  const isAttachmentEmpty = !(documentRevision?.attachments?.length > 1);
  const isCurrentUserOperator = useContext(isOperator);
  const isDisabledInput = formik.values.id !== undefined && isCurrentUserOperator && !isOwner;
  const isOutput = true;
  const showAdministrativeAndVoidChange = false;
  const type = DocumentCloneTypeOptions.none;
  const mode = FBWorkspaceModeOptions.FORM;

  const isPart = checkIsDocumentPart(documentType.groupOptions);
  const isPartForm = useSelector(
    (state: ApplicationState) => documentRevision?.formTemplate
      ? checkIsDocumentPart((documentRevision?.formTemplate.outputDocumentTypes[0] as FBOutputDocumentType)?.groupOptions)
      : false,
  );
  const isOther = checkIsDocumentOTHER(documentRevision.document.documentType.groupOptions);
  const isOtherForm = useSelector(
    (state: ApplicationState) => documentRevision?.formTemplate
      ? checkIsDocumentOTHER((documentRevision?.formTemplate.outputDocumentTypes[0] as FBOutputDocumentType)?.groupOptions)
      : false);

  const smartReferencesFrom = documentRevision?.smartReferencesFrom?.filter((ref) =>
    ref.active && ref.type === SmartReferenceType.HiddenPiInstance
    && schema?.map((el) => el.name).filter((el) => el?.includes(ref.metadata.fieldId)));
  const isRelatedEquipmentsShowing = shouldShowRelatedEquipmentsTable(documentRevision);
  let tabConfig = useSelector((state: ApplicationState) => documentTypeSelectors.getTabConfiguration(state, group));
  useEffect(() => {
    if (isLHR) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
      schema = (schema || []).map((schemaItem: FBSchemaProps, i, ro) => ({
        ...schemaItem,
        index: i,
        ...(includes(FBEditorState.pickStepIndex, schemaItem.type) && {
          stepIndex: size(
            filter(
              ro.slice(0, i + 1),
              (step) =>
                !step.deleted && includes(FBEditorState.pickStepIndex, step.type),
            ),
          ),
        }),
      }));

      smartReferencesFrom?.forEach((ele) => {
        const obj = schema?.filter((el) => ele?.metadata?.fieldId === el.name);
        ele.stepIndex = obj?.[0]?.stepIndex;
      });
    }
  }, [isLHR]);

  if (tabConfig && [DOC_TYPE_GROUP.LHR, DOC_TYPE_GROUP.LHRT, DOC_TYPE_GROUP.ENGINEERING_BUILD, DOC_TYPE_GROUP.PI]
    .includes(group as DOC_TYPE_GROUP)) {
    tabConfig = [...tabConfig, {
      labelId: 'tabId.materialsandequipment',
      tabId: TabTypeOptions.TABS_MEQ.toLowerCase(),
      type: TabTypeOptions.TABS_MEQ,
    }];
  }

  if (formik.values?.retrain && tabConfig) {
    tabConfig = [...tabConfig, {
      labelId: 'tabId.training',
      tabId: TabTypeOptions.TRAINING.toLowerCase(),
      type: TabTypeOptions.TRAINING,
    }];
  }

  if (tabConfig && [DOC_TYPE_GROUP.PART]
    .includes(group as DOC_TYPE_GROUP)) {
    tabConfig = [...tabConfig, {
      labelId: 'tabId.bom',
      tabId: TabTypeOptions.BOM.toLowerCase(),
      type: TabTypeOptions.BOM,
    }];
  }

  if (tabConfig && [DOC_TYPE_GROUP.PAPER_LHR]
    .includes(group as DOC_TYPE_GROUP)) {
    tabConfig = [...tabConfig, {
      labelId: 'tabId.summary',
      tabId: TabTypeOptions.SUMMARY.toLowerCase(),
      type: TabTypeOptions.SUMMARY,
    }];
  }

  const prevMode = useRef<FBWorkspaceMode>();

  useEffect(() => {
    workspaceState?.setIsPreview(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (workspaceState?.mode) {
      prevMode.current = workspaceState?.mode;
    }

    _formState?.setValues(initialValues);
    _formState?.setMode(mode);
    workspaceState?.setMode(mode);

    if (isWO && !documentRevision.formTemplate && initialValues?.formInput?.work_order_type === WorkOrderTypes.OTHER) {
      // hide allocation & BOM Tabs on Work order Other case
      tabConfig = tabConfig.filter(tab => ![TabTypeOptions?.BOM, TabTypeOptions?.ALLOCATION].includes(tab.type as TabTypeOptions));
    }

    return () => {
      if (prevMode.current) {
        workspaceState?.setMode(prevMode.current);
      }
    };
  }, [_formState, initialValues, workspaceState, mode]);

  useEffect(() => () => {
    documentRevision.document.documentType = documentType;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Observer>
      {() => (
        <SMTemplate
          templateBoxProps={{
            stretchY: '100%',
            mx: -3.75,
            mt: -3,
          }}
          contentBoxProps={{
            overflow: 'unset',
          }}
          header={(!isLHR && !isTypeMpiDoc) && !formPreview && (
            <DocumentRevisionFormHeader type={type} documentTypeId={formik.values.document?.documentType?.id} />
          )}
          lhrSteps={isLHR && !formPreview && (
            <DocumentRevisionFormProgressBar smartReferencesFrom={smartReferencesFrom} />
          )}
        >
          <Observer>
            {() => (
              <SMTabs
                tabs={tabConfig}
                boxProps={{ px: 3.75, stretchY: '100%', display: 'flex', flexDirection: 'column' }}
                panelProps={{ flex: 1 }}
              >
                {map(tabConfig, (tabInfo: TabInfo) => {
                  const tabType = tabInfo.type;
                  const showTabContent = _tabsState?.isTabActive(tabInfo.tabId);

                  return (
                    <SMBox key={tabInfo.tabId} className={classes.previewTabContent} pr={0.75}>
                      {tabType === TabTypeOptions.MIXED && <>
                        {!formPreview && <DocumentRevisionFormPresenter
                          {...formik}
                          isPreview={true}
                          redlineActive={false}
                          releasedDocRev={_documentRevisionFormState?.releasedDocRev}
                          documentRevision={documentRevision}
                          proposedDocId={docRevId}
                          documentTypesById={documentTypesById}
                          canChangeOwnerShip={false}
                          resetForm={emptyFunction}
                          {...{
                            type,
                            showAdministrativeAndVoidChange,
                            isOutput,
                            email,
                            isOwner,
                            mode,
                          }}
                        />}
                        {(isPart || isPartForm) && formPreview && (
                          <Grid item xs={12}>
                            <LifecyclePhaseSelect
                              docTypeId={docTypeId}
                              lifecyclePhaseId={
                                  documentRevision?.lifecyclePhaseId
                              }
                              isPartForm={isPartForm}
                            />
                          </Grid>
                        )}
                        {((isPart || isPartForm || isOther || isOtherForm) && formPreview) && (
                          <Grid item xs={12}>
                            <RelatedPartsWrapper
                              viewOnly={true}
                              documentRevision={documentRevision}
                              relatedPartsStatus= {documentRevision?.relatedPartsStatus}
                            />
                          </Grid>
                        )}
                        {documentRevision && showTabContent
                        && <FBSchema
                          {...{ schema }}
                          schemaState={previewWorkspaceState}
                          workspaceMode={mode}
                        />
                        }
                        {formPreview && isRelatedEquipmentsShowing && (
                          <Grid item xs={12}>
                            <RelatedEquipments parentDocumentRevision={documentRevision} />
                          </Grid>
                        )}
                      </>}
                      {tabType === TabTypeOptions.DYNAMIC && showTabContent
                        && _formState?.mode !== FBWorkspaceModeOptions.NONE && (
                        <>
                          {tabInfo.tabId === 'lineitems' && (
                            <AttachmentsField
                              isDisabled={isDisabledInput}
                              isNewVersion={false}
                              docRevId={docRevId || ''}
                              isAttachmentEmpty={isAttachmentEmpty}
                              containsRedline={false}
                              docRevStatus={documentRevision?.status}
                              documentRevision={documentRevision}
                              documentTypeId={docTypeId || ''}
                              labeld="Supporting Documents"
                            />
                          )}
                          <FBSchema
                            {...{ schema }}
                            schemaState={previewWorkspaceState}
                            workspaceMode={mode}
                          />
                        </>
                      )}
                      {tabType === TabTypeOptions.TABS_MEQ && (
                        <FBMaterialEqInfoContainer
                          documentRevision={documentRevision}
                          inView={Boolean(_tabsState?.isTabActive(TabTypeOptions.TABS_MEQ))}
                        />
                      )}
                      {tabType === TabTypeOptions.TRAINING && _tabsState?.isTabActive(TabTypeOptions.TRAINING) && (
                        <FBTrainingInfoContainer
                          documentRevisionId={documentRevision?.id}
                          docRevStatus={documentRevision?.status}
                        />
                      )}
                      {tabType === TabTypeOptions.SUMMARY && _tabsState?.isTabActive(TabTypeOptions.SUMMARY) && (
                        <FBLHRSummary isShowOnly={false} isDisabled={true} />
                      )}
                      {tabType === TabTypeOptions.ALLOCATION && _tabsState?.isTabActive(TabTypeOptions.ALLOCATION) && (
                        <FBAllocationTreeList documentRevision={documentRevision} isDisabled={true} />
                      )}
                      {tabType === TabTypeOptions.BOM && _tabsState?.isTabActive(TabTypeOptions.BOM) && (
                        <FBBOM documentRevision={documentRevision}
                          isDisabled={true} isShowOnly={true} isWO={isWO} />
                      )}
                      {tabType === TabTypeOptions.MATERIAL_DISPOSITION
                        && _tabsState?.isTabActive(
                          TabTypeOptions.MATERIAL_DISPOSITION,
                        ) && <FBMaterialDisposition isShowOnly />}
                    </SMBox>
                  );
                })}
              </SMTabs>
            )}
          </Observer>
        </SMTemplate>
      )}
    </Observer>
  );
};

const DocumentPreviewContainer: React.FunctionComponent<ContainerProps> = (props) => {
  const { workspaceState } = FB.useStores();

  const previewWorkspaceState = FB.useRef<FBWorkspaceState>(FBWorkspaceState, {
    schema: workspaceState?.schema,
    mode: FBWorkspaceModeOptions.FORM,
    autosave: false,
  });

  previewWorkspaceState.document = cloneDeep(workspaceState?.document);

  const formik = useFormikContext<DocumentRevisionFormValues>();
  const initialValues = formik?.values ?? workspaceState?.document;

  return (
    <FBProvider workspaceState={previewWorkspaceState}>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={emptyFunction}
      >
        {(formikProps) => <DocumentPreview {...props} formik={formikProps} />}
      </Formik>
    </FBProvider>
  );
};

export default withSMForm(withSMTabs(DocumentPreviewContainer));
