export const AUTH_USER = 'auth/AUTH_USER';
export const LOGOUT_USER = 'auth/LOGOUT_USER';
export const USER_GROUPS = 'auth/USER_GROUPS';
export const SET_EMPLOYEE = 'auth/SET_EMPLOYEE';
export const SET_CREDENTIALS = 'auth/SET_CREDENTIALS';
export const CLEAR_NON_AUTH_DATA = 'auth/CLEAR_NON_AUTH_DATA';
export const TOGGLE_CUSTOM_LABELS = 'auth/TOGGLE_CUSTOM_LABELS';

export const USER_GROUPS_GET_ASYNC = 'auth/USER_GROUPS_GET_ASYNC';
export const PING_SERVER_GET_ASYNC = 'auth/PING_SERVER_GET_ASYNC';

export const LOGIN_URL = '/auth/login';
export const SIGNUP_URL = '/auth/sign_up';
export const FORGOT_PASSWORD_URL = '/auth/forgot_password';
export const CONFIRM_FORGOT_PASSWORD_URL = '/auth/confirm_forgot_password';
export const CONFIRM_SET_NEW_PASSWORD_URL = '/auth/new_user_set_password';
export const LOGIN_WITH_CODE_URL = '/auth/tokens';
export const LOGOUT_URL = '/auth/logout';
export const AUTH_AWS_IOT_URL = '/auth/credentials/iot';
export const GROUPS_URL = '/groups/me';
export const PING_SERVER_URL = '/auth/prevent_idle_logout';
export const CHANGE_PASSWORD_URL = '/auth/change_password';

export const FETCH_VALIDATORS_REMOTE_URL_URL = '/remoteValidators';
export const REMOTE_VALIDATORS_STATUS_URL = '/remoteValidators/status';
export const VALIDATORS_HELPER_REMOTE_URL_URL = '/remoteValidators/helper';

export const SESSION_DETAILS_URL = '/auth/sessionDetails';
export const USERS_URL = '/users';
export const USERS_ME_URL = '/users/me';

export const RESEND_SIGNUP_EMAIL_URL = '/auth/resend_confirmation_email';
export const SEND_PASSWORD_RESET_EMAIL = '/auth/send_password_reset_email';
