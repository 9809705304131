import { makeStyles, Theme } from '@material-ui/core';

export const styles = makeStyles((theme: Theme) => ({
  groupCell: {
    whiteSpace: 'pre-wrap',
  },
  link: {
    fontSize: 14,
  },
}));
