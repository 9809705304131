export default {
  0: (state: any) => ({
    ...state,
  }),
  1: (state: any) => ({
    ...state,
    criteria: undefined,
  }),
  2: (state: any) => ({
    ...state,
    criteria: undefined,
  }),
  3: (state: any) => ({
    ...state,
    criteria: undefined,
  }),
};
