import { combineReducers } from 'redux';
import { createMigrate, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import attachments from './ducks/attachments';
import auth from './ducks/auth';
import { CLEAR_NON_AUTH_DATA, LOGOUT_USER } from './ducks/auth/constants';
import bulkImport from './ducks/bulkImport';
import changeRequests from './ducks/changeRequest';
import company from './ducks/company';
import dashboard from './ducks/dashboard';
import dashboardNew from './ducks/dashboard.new';
import documentRevisions from './ducks/documentRevisions';
import documentType from './ducks/documentRevisions/documentType';
import documents from './ducks/documents';
import pageDetails from './ducks/pageDetails';
import supplierSurvey from './ducks/supplierSurvey';
import tableSearch from './ducks/tableSearch';
import userManagement from './ducks/userManagement';
import { authMigrations, pageDetailsMigrations, tableSearchMigrations } from './migrations';
import { authTransform } from './transforms';

const authPersistConfig = {
  key: 'auth',
  storage,
  version: 3,
  whitelist: ['user', 'groups'],
  transforms: [authTransform],
  migrate: createMigrate(authMigrations),
};

const companyPersistConfig = {
  key: 'company',
  storage,
  whitelist: ['selectedCompany'],
};

const documentTypePersistConfig = {
  key: 'documentType',
  storage,
  whitelist: ['byId'],
};

const tableSearchPersistConfig = {
  key: 'tableSearch',
  storage,
  version: 3,
  whitelist: ['criteria'],
  migrate: createMigrate(tableSearchMigrations),
};

const pageDetailsPersistConfig = {
  key: 'pageDetails',
  storage,
  version: 1,
  whitelist: ['byCategory'],
  migrate: createMigrate(pageDetailsMigrations),
};

const appReducer = combineReducers({
  attachments,
  auth: persistReducer(authPersistConfig, auth),
  documents,
  changeRequests,
  documentRevisions,
  dashboard,
  dashboardNew,
  documentType: persistReducer(documentTypePersistConfig, documentType),
  company: persistReducer(companyPersistConfig, company),
  tableSearch: persistReducer(tableSearchPersistConfig, tableSearch),
  supplierSurvey,
  userManagement,
  pageDetails: persistReducer(pageDetailsPersistConfig, pageDetails),
  bulkImport,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === LOGOUT_USER || action.type === CLEAR_NON_AUTH_DATA) {
    const { auth: authState } = state;
    const { company: companyState } = state;
    const { tableSearch: tableSearchState } = state;
    state = {
      auth: authState,
      company: companyState,
      tableSearch: tableSearchState,
    };
  }

  return appReducer(state, action);
};

export type ApplicationState = ReturnType<typeof rootReducer>;
export default rootReducer;
