import { makeStyles } from '@material-ui/core';
import Colors from '../layout/theme/utils/colors';
import { NextTheme } from '../layout/theme-next/provider';

export const loadMoreStyles = (theme: NextTheme) => ({
  margin: theme.spacing(5, 1, 0.5),
});

export default makeStyles((theme: NextTheme) => ({
  box: {
    height: '100%',
    boxShadow: '0 0 25px rgba(41, 41, 41, 0.2)',
    borderRadius: 12,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    padding: theme.spacing(1, 1, 0),
    background: Colors.white,
    marginRight: 50,
    marginLeft: 50,
    zIndex: 1,
    position: 'relative',
  },
  root: {
    width: '100%',
    height: '100%',
    margin: 'auto',
    overflow: 'hidden',
  },
  tabs: {
    height: '100%',
  },
  stats: {
    height: '100%',
    paddingTop: 56,
  },
  loadMore: loadMoreStyles(theme),
  searchContainer: {
    maxWidth: 500,
    marginTop: -78,
  },
  [theme.media.mobile]: {
    searchContainer: {
      display: 'none',
    },
    box: {
      margin: theme.spacing(0, 1.25),
    },
    stats: {
      display: 'none',
    },
  },
}));
